<template>
	<div class="wrap__content">
		<div class="container">
			<div class="row">
				<div class="col-xl-2 col-lg-3">
					<v-sidebar :active="['ReferenceManagement']"></v-sidebar>
				</div>
				<div class="col-xl-10 col-lg-9">
					<div class="content-wrapper">
						<div class="card__content">
							<div class="card__content-header">
								<div class="content__title">
									<div class="content__title--element">
										<div class="content__title--text">
											{{ $t('sidebar.references') }}
										</div>
									</div>
								</div>
							</div>
							<div class="card__content--body">
								<div class="table__block--content">
									<div class="content__list--links">
										<ul class="content__links--ul">
											<li v-for="handbook in handbooks" :key="handbook.name">
												<router-link :to="handbook.to" class="tb__table--link">
													{{ $t('handbooks.' + handbook.name) }}
												</router-link>
											</li>
										</ul>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			handbooks: [
				{
					name: 'Водоемы',
					to: 'reservoirs',
				},
				{
					name: 'Области',
					to: 'regions',
				},
			],
		}
	},
	methods: {},
	computed: {},
	beforeCreate() {
		if (!localStorage.token) {
			this.$router.push('/ru/login')
		}
	},
	head: {
		title() {
			return {
				inner: this.$t('sidebar.references'),
			}
		},
		meta: [],
	},
}
</script>
